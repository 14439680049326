import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { login } from '../../actions/userActions';
import PhoneAuth from '../PhoneAuth';
import Menu from '../Menu';
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  position: relative;
  background-color: ${props => props.backgroundColor};
  @media screen and (min-width: 500px){
    max-height: 700px;
  }
`;

function Login({ login }) {
  return (
    <Container>
      <Menu />
      <PhoneAuth buttonText="Login" submit={login} />
    </Container>
  );
}

const mapDispatchToProps = { login };

export default connect(null, mapDispatchToProps)(Login);
