import React from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import Component from '../components/Login';

function Login(props) {
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
}

const mapStateToProps = (state) => ({ ...state.dropsReducer });

export default connect(mapStateToProps)(Login);
